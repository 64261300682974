import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://github.com/benlt44-code" target="_blank" rel="noreferrer"><GitHubIcon/></a>
        <a href="https://www.linkedin.com/in/benjamin-le-terte-1b1b03279/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
      </div>
      <p>Un portfolio conçu et réalisé par <a href="https://github.com/benlt44-code" target="_blank" rel="noreferrer">Benjamin Le Terte</a></p>
    </footer>
  );
}

export default Footer;