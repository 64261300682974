import React, { useRef, useState } from 'react';
import '../assets/styles/Contact.scss';
// import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

function Contact() {

  const [nom, setNom] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [erreurNom, setErreurNom] = useState<boolean>(false);
  const [erreurEmail, setErreurEmail] = useState<boolean>(false);
  const [erreurMessage, setErreurMessage] = useState<boolean>(false);

  const formulaire = useRef();

  const envoyerEmail = (e: any) => {
    e.preventDefault();

    setErreurNom(nom === '');
    setErreurEmail(email === '');
    setErreurMessage(message === '');

    /* Décommentez ci-dessous si vous voulez activer emailJS */

    // if (nom !== '' && email !== '' && message !== '') {
    //   var templateParams = {
    //     nom: nom,
    //     email: email,
    //     message: message
    //   };

    //   console.log(templateParams);
    //   emailjs.send('service_id', 'template_id', templateParams, 'api_key').then(
    //     (response) => {
    //       console.log('SUCCÈS!', response.status, response.text);
    //     },
    //     (error) => {
    //       console.log('ÉCHEC...', error);
    //     },
    //   );
    //   setNom('');
    //   setEmail('');
    //   setMessage('');
    // }
  };

  return (
    <div id="contact">
      <div className="items-container">
        <div className="contact_wrapper">
          <h1>Contactez-moi</h1>
          <p>Vous avez un projet en attente de réalisation ? Collaborons et réalisons-le ensemble !</p>
          <Box
            ref={formulaire}
            component="form"
            noValidate
            autoComplete="off"
            className='contact-form'
          >
            <div className='form-flex'>
              <TextField
                required
                id="outlined-required"
                label="Votre Nom"
                placeholder="Quel est votre nom ?"
                value={nom}
                onChange={(e) => {
                  setNom(e.target.value);
                }}
                error={erreurNom}
                helperText={erreurNom ? "Veuillez entrer votre nom" : ""}
              />
              <TextField
                required
                id="outlined-required"
                label="Email / Téléphone"
                placeholder="Comment puis-je vous joindre ?"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={erreurEmail}
                helperText={erreurEmail ? "Veuillez entrer votre email ou numéro de téléphone" : ""}
              />
            </div>
            <TextField
              required
              id="outlined-multiline-static"
              label="Message"
              placeholder="Envoyez-moi toute question ou demande"
              multiline
              rows={10}
              className="body-form"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              error={erreurMessage}
              helperText={erreurMessage ? "Veuillez entrer le message" : ""}
            />
            <Button variant="contained" endIcon={<SendIcon />} onClick={envoyerEmail}>
              Envoyer
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Contact;
