import React from "react";
import mock01 from '../assets/images/mock01.png';
import mock02 from '../assets/images/mock02.png';
import mock03 from '../assets/images/mock03.png';
import mock04 from '../assets/images/mock04.png';
import mock05 from '../assets/images/mock05.png';
import mock11 from '../assets/images/mock11.jpg';
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>Projets pour les épreuves E4 et E5</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://www.filmate.club/" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock11} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Projet d'évolution des postes d'instructions (métiers)</h2>
                </a>
                <p>Modernisation des postes de travail avec des écrans tactiles ergonomiques pour simplifier l'interaction des utilisateurs, en particulier pour les opérations de conception 3D. Ce projet a impliqué des tests sur divers équipements, des retours utilisateurs, et le déploiement de dispositifs robustes et sécurisés adaptés à l'industrie.</p>
            </div>

            <div className="project">
                <a href="https://yujisatojr.itch.io/highspeedchase" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock03} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="../assets/images/stationblanche.pdf" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Migration PDA vers nouveau MDM (Ivanti vers Intune)</h2>
                </a>
                <p>Migration stratégique de tous les PDA vers Microsoft Intune pour une meilleure gestion et sécurisation des appareils. Ce projet a requis une planification approfondie, une coordination avec plusieurs équipes, et des formations sur site pour assurer une transition fluide sans perturber les opérations logistiques.</p>
            </div>

            <div className="project">
                <a href="https://yujisatojr.itch.io/spacecraft" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock01} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="../assets/images/stationblanche.pdf" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Analyses d'incidents liés à l'EDR (Taegis). Mise en œuvre d'une solution pérenne</h2>
                </a>
                <p>Résolution des problèmes de performance causés par l'agent EDR Taegis sur Adobe InDesign...</p>
            </div>

            <div className="project">
                <a href="https://www.datumlearn.com/" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock02} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="../assets/images/stationblanche.pdf" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Préparation et déploiement d’un script visant à accueillir des stations blanches</h2>
                </a>
                <p>Développement d'un script automatisé et sécurisé pour configurer des "stations blanches"...</p>
            </div>

            <div className="project">
                <a href="https://hookele.byuh.edu/transfer-evaluation-guidelines-and-matrix" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock05} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="../assets/images/stationblanche.pdf" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Dossier complet pour l'Épreuve E4</h2>
                </a>
                <p>Documentation complète détaillant les projets techniques, avec analyses et solutions adaptées...</p>
            </div>

            <div className="project">
                <a href="https://github.com/yujisatojr/submeowrine" target="_blank" rel="noreferrer">
                    <div className="image-container">
                        <img src={mock04} className="zoom" alt="thumbnail" width="100%"/>
                        <span className="hover-text">Télécharger le projet</span>
                    </div>
                </a>
                <a href="../assets/images/stationblanche.pdf" download="stationblanche.pdf" target="_blank" rel="noreferrer">
                    <h2>Dossier complet pour l'Épreuve E5</h2>
                </a>
                <p>Dans le cadre du projet Dualya, ce dossier documente la mise en place d’une infrastructure réseau...</p>
            </div>
        </div>
    </div>
    );
}


export default Project;