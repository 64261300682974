import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "Cisco",
    "OPNsense",
    "VLAN",
    "VPN",
    "Routing Protocols (OSPF, EIGRP)",
    "SNMP",
    "Wi-Fi",
    "Spanning Tree Protocol (STP)",
    "Firewall",
    "Port-security",
    "NAT",
];

const labelsSecond = [
    "Windows Server (AD, DHCP, DNS)",
    "Linux (Ubuntu, CentOS)",
    "Virtualisation (VMware, Hyper-V)",
    "PowerShell",
    "Bash Scripting",
    "Samba",
    "NFS",
    "Backup Solutions (Veeam, Rsync)",
    "Fail2Ban",
    "UFW",
];

const labelsThird = [
    "StomrShield",
    "OPNsense",
    "Iptables",
    "Certificats",
    "EDR"

];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Skills</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faReact} size="3x"/>
                    <h3>Administration Réseau</h3>
                    <p>Mise en place et la gestion d'infrastructures réseau sécurisées, incluant VLAN, VPN, pare-feu (OPNsense), et protocoles de routage (OSPF, RIPV2) pour assurer une connectivité fiable et protégée.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>Administration Système</h3>
                    <p>Mise en place d'infrastrucutre systèmes complétes , ADDS serveurs linux pour difféents services .Dans une démarche de redondance et de haute disponibilité .</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faPython} size="3x"/>
                    <h3>Initiation Cybersécurité</h3>
                    <p>Toujours dans une démarche de veille afin de m'actualisé sur les dernières infos.De plus mettre mes connaissances acquises pour liées c'est compétence avec l'administration systèmes et réseaux.
                    </p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;